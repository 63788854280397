import React from 'react';
import Widget from '../verticalWidget2';

const CategoryWidget13 = (props) => {
  return (
    <div>
      <Widget cardType={1} {...props} />
    </div>
  );
};

export default CategoryWidget13;
